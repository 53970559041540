<template>
  <div class="page-content">
    <h1 class="page-content__title">Input</h1>
    <p class="page-content__description">
    Текстовые поля — основная категория инпутов. Используется для заполнения различных форм, контактных и личных данных, адресов доставки и в других похожих случаях. Инпут выражен чуть затемненным прямоугольником с закругленными под 12 пикселей углами и легким контуром для более явного отличия от кнопок и селекторов. В фокусе контур инпута становится контрастнее, в том числе в состоянии ошибки. 
    </p>
    <h3 class="page-content__title">Простой инпут</h3>
    <p>В простом инпуте заголовок перекрывается введенными данными. Может использоваться для простых форм с одним полем ввода, где содержимое инпута понятно из заголовка экрана.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Email</h3>
    <p>С email полями ввода необходимо использовать адаптированную для email клавиатуру</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Телефон</h3>
    <p>Номер телефона вводится без скобок и дефисов. Также необходимо использовать соответствующую цифровому набору клавиатуру</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Дата</h3>
    <p>Дата всегда вводится с помощью нативного пикера, соответственно у нее нет состояния фокуса, только пустое и заполненное.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Адрес</h3>
    <p>Инпуты адресов используются вместе со списком результатов поиска по адресу и валидируются после каждого введенного символа. Подтвердить ввод можно лишь выбрав нужный адрес из списка. Номер квартиры и другие подробности для доставки указываются в других полях рядом. После подтверждения адрес сокращается до улицы и дома.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Инпут с иконкой</h3>
    <p>Иконки в инпутах обычно выполняют какуб-либо функцию: вызывают тултип с подробным описанием, открывают карту для поиска адреса, показывают введенный пароль и т.д. Не стоит использовать иконку только для иллюстрации смысла содержимого</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Пароль</h3>
    <p>Может быть двух видов: с подсказками (при регистрации) и без (для авторизации).</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Текстовое поле в несколько строк</h3>
    <p>Бывает с заголовком и без. Используется для сообщений, комментариев, описаний, био в профиле и везде, где необходимо ввести большой объем информации. Растягивается до 6 строк, после чего появляется прокрутка.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Поле для ввода кода подтверждения</h3>
    <p>Бывает из 5 цифр и из 4 цифр</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
    <h3 class="page-content__title">Обязательные поля</h3>
    <p>Для обязятельных полей в заголовке инпута используем звездочку. Чтобы сделать инпут обязательным, нужно выбрать сам заголовок внутри компонента и переключить параметр  Required в состояние On.</p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4004%253A31702" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'Input',
};
</script>

<style lang="scss" scoped>
</style>